import axios from "axios";
import i18n from "../i18n";
import * as qs from "qs";
import { moreItemsOption } from "../Utils/Autocomplete";
import { SelectAllStates } from "./applicantsSelectedContext";
import { MAX_CSV_EXPORT_RECORDS } from "../constants";

/**
 * Fetch the List of Assigned Submissions,
 * to be displayed in Submissions Autocomplete List
 * @param {*} setSubmissionList
 */
export const getSubmissionsList = async (
  setSubmissionList,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const request = {
      url: `/submissions`,
      params: {
        vw: "brief",
        ps: 500,
      },
    };
    const response = await axios(request);
    if (response.data.count) {
      setSubmissionList(response.data.values);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

const retrieveGetApplicantParams = (applicantsFilterPreferences) => {
  const { pagination, sort } = applicantsFilterPreferences.applicantValues;
  const { filters } = applicantsFilterPreferences.applicantValues;
  const traineeTypeCode = filters.applicantType;

  const {
    applicant,
    departmentCode,
    applicationYear,
    acceptedYear,
    newEntrant,
    grantEligible,
    isActive,
  } = filters;
  const { page, pageSize } = pagination;
  const { order, orderBy } = sort;
  return {
    applicantName: applicant?.value?.fullName,
    department: departmentCode,
    applicantType: traineeTypeCode,
    applicationYear,
    acceptedYear,
    newEntrant,
    grantEligible,
    isActive: isActive ? true : "",
    p: +page + 1,
    ps: pageSize || 10,
    s: `${orderBy ? `${orderBy}` : `applicantName`}:${
      order ? `${order}` : `asc`
    }`,
  };
};

export const getApplicantsExportData = async (
  selectedSubmission,
  applicantsFilterPreferences,
  totalCount,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const requestParams = retrieveGetApplicantParams(
      applicantsFilterPreferences
    );
    let request = "";
    let response = "";
    if (selectedSubmission != null) {
      request = {
        url: `/submissions/${selectedSubmission}/applicants`,
        params: {
          ...requestParams,
          p: 1,
          ps: totalCount,
        },
        responseType: "blob",
        headers: {
          Accept: "application/vnd.ms-excel",
          "Content-type": "application/vnd.ms-excel",
        },
      };

      response = await axios(request);
      if (response.data) {
        const currentDate = new Date();
        const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
        const date = ("0" + currentDate.getDate()).slice(-2);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export_Applicants_${currentDate.getFullYear()}${month}${date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

/* New API Service level implementation */

export const getAllApplicantsList = async (
  setApplicantsList,
  setSummary,
  selectedSubmission,
  setLoading,
  applicantsFilterPreferences,
  history,
  setAlert,
  clearAlert,
  setMetaData,
  getSelectedSubmissionName
) => {
  const requestParams = retrieveGetApplicantParams(applicantsFilterPreferences);

  try {
    clearAlert();

    setLoading(true);

    let request = "";
    let response = "";
    if (selectedSubmission) {
      request = {
        url: `/submissions/${selectedSubmission}/applicants`,
        params: requestParams,
      };

      response = await axios(request);
      const summary = { totalCount: 0, totalActiveCount: 0 };

      if (requestParams.isActive === true) {
        summary.totalActiveCount = response.data.totalCount;
      } else if (requestParams.isActive === false) {
        summary.totalActiveCount = 0;
      } else {
        const requestActive = {
          url: `/submissions/${selectedSubmission}/applicants`,
          params: { ...requestParams, isActive: true },
        };

        const responseActive = await axios(requestActive);
        summary.totalActiveCount = +responseActive.data.totalCount;
      }

      setMetaData(response.data.meta);
      setApplicantsList(response.data);
      const exportMessage =
        response.data.totalCount > MAX_CSV_EXPORT_RECORDS
          ? i18n.t("globals.metaDataInfo.exportTitleTooMany")
          : i18n.t("globals.metaDataInfo.exportTitleNormal");
      setSummary({
        ...summary,
        totalCount: response.data.totalCount,
        exportMessage: exportMessage,
      });
    } else {
      setMetaData(false);
      setApplicantsList({});
      setSummary({ totalCount: 0 });
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * @param {*} selectedSubmission
 * @param {*} applicantId
 * @param {*} setResponse
 */
export const getApplicantById = async (
  selectedSubmission,
  applicantId,
  setApplicantFormValues,
  setAlert,
  clearAlert,
  setLoading,
  applicationTypeDropDown,
  applicantFormValues,
  history
) => {
  try {
    clearAlert();
    setLoading(true);
    const request = {
      url: `/submissions/${selectedSubmission}/applicants/${applicantId}`,
    };
    const response = await axios(request);
    setApplicantFormValues({
      ...applicantFormValues,
      ...response.data,

      applicantType: response.data.applicantType,
    });
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * Fetch the List of all Applicants,
 * (using the same trainee fetch end-point)
 * to be displayed in Applicant Autocomplete List
 */
export const getApplicantList = async (
  val,
  yearsToGoBack,
  setApplicants,
  setAlert,
  clearAlert,
  setOptionsLoading,
  submissionId
) => {
  try {
    clearAlert();
    setOptionsLoading(true);

    const fetchSize = 50;

    if (val) {
      const request = {
        url: `/applicants/autocomplete`,
        params: {
          value: val,
          size: fetchSize,
          submissionId,
          yearsToGoBack: yearsToGoBack,
        },
        paramsSerializer: {
          serialize: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        },
      };
      const response = await axios(request);

      if (response?.data?.values?.length >= fetchSize) {
        response.data.values.push(moreItemsOption(fetchSize));
      }
      setApplicants(response.data);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setOptionsLoading(false);
  }
};

export const getApplicantAutocomplete = async (
  parameter,
  size,
  targetValue,
  setAutoCompleteData,
  setAlert,
  clearAlert
) => {
  let includeOrgsWithAcademicPlanCodeOnly = false;
  try {
    clearAlert();
    if (parameter === "program") {
      parameter = "department";
      includeOrgsWithAcademicPlanCodeOnly = true;
    }
    const request = {
      url: `${parameter}s/autocomplete?size=${size}&value=${targetValue}&includeOrgsWithAcademicPlanCodeOnly=${includeOrgsWithAcademicPlanCodeOnly}`,
    };
    const response = await axios(request);
    setAutoCompleteData(response.data);
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const postCreateApplicant = async (
  submissionId,
  requestData,
  props,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    let addApplicantFormValues = Object.assign({}, requestData);

    if (
      addApplicantFormValues.applicantType &&
      typeof addApplicantFormValues.applicantType["key"] !== "undefined"
    ) {
      addApplicantFormValues.applicantType =
        addApplicantFormValues.applicantType["key"];
    }

    // ** Request Object **
    const request = {
      method: "POST",
      url: `/submissions/${submissionId}/applicants`,
      data: { ...addApplicantFormValues },
    };

    await axios(request);

    // **** Redirect to /faculty on Successful form Submit ****
    props.history.push({
      pathname: "/applicants",
    });
    setAlert(
      "success",
      i18n.t("applicants.create.notification.success", {
        applicantName: `"${
          addApplicantFormValues.applicantLastName.trimRight() +
          " , " +
          addApplicantFormValues.applicantFirstName.trimRight()
        }"`,
      }),
      true
    );
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const putCreateApplicant = async (
  submissionId,
  applicantId,
  requestData,
  props,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const editApplicantFormValues = { ...requestData };

    if (
      editApplicantFormValues.applicantType &&
      typeof editApplicantFormValues.applicantType["key"] !== "undefined"
    ) {
      editApplicantFormValues.applicantType =
        editApplicantFormValues.applicantType["key"];
    }

    // ** Request Object **
    const request = {
      method: "PUT",
      url: `/submissions/${submissionId}/applicants/${applicantId}`,
      data: { ...editApplicantFormValues },
    };

    await axios(request);

    // **** Redirect to /faculty on Successful form Submit ****
    props.history.push({
      pathname: "/applicants",
    });
    setAlert(
      "success",
      i18n.t("applicants.create.notification.update", {
        applicantName: `"${
          editApplicantFormValues.applicantLastName.trimRight() +
          " , " +
          editApplicantFormValues.applicantFirstName.trimRight()
        }"`,
      }),
      true
    );
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const removeAddApplicantById = async (
  selectedSubmission,
  applicantId,
  applicantData,
  setSnackbar,
  setAlert,
  clearAlert,
  setLoading,
  applicantsFilterPreferences,
  setApplicantsFilterPreferences
) => {
  try {
    setLoading(true);
    clearAlert();
    if (applicantId) {
      const request = {
        method: "PATCH",
        url: `/submissions/${selectedSubmission}/applicants/${applicantId}`,
        data: { ...applicantData, isActive: !applicantData.isActive },
      };
      const response = await axios(request);
      applicantData.isActive = response.data.isActive;
      setSnackbar(true);

      setApplicantsFilterPreferences({
        ...applicantsFilterPreferences,
        applicantValues: {
          ...applicantsFilterPreferences.applicantValues,
          renderCount:
            applicantsFilterPreferences.applicantValues.renderCount + 1,
        },
      });
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const postSaveApplicant = async (
  setSnackbar,
  selectedSubmission,
  applicantData,
  setRefreshedApplicantID,
  setAlert,
  clearAlert,
  setLoading,
  setKey,
  applicantsFilterPreferences,
  setApplicantsFilterPreferences
) => {
  try {
    setLoading(true);
    clearAlert();
    if (applicantData) {
      const request = {
        method: "post",
        url: `/submissions/${selectedSubmission}/applicants/add`,
        data: applicantData,
      };
      const response = await axios(request);
      if (response && response.status === 201) {
        setSnackbar({
          show: true,
          message: "applicants.mainView.addApplicantMessage",
        });

        setRefreshedApplicantID(response.data.trainee.universityId);
        setApplicantsFilterPreferences({
          ...applicantsFilterPreferences,
          applicantValues: {
            ...applicantsFilterPreferences.applicantValues,
            renderCount:
              applicantsFilterPreferences.applicantValues.renderCount + 1,
          },
        });
      }
    }
  } catch (error) {
    const errorResponse = error.response.data;

    if (
      errorResponse.status === 409 &&
      errorResponse.code === "RESOURCE_EXISTS"
    ) {
      setAlert("error", i18n.t("applicants.mainView.duplicateApplicant"));
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
    setKey(`add-${new Date().toString()}`);
  }
};
export const getAllSelectedApplicantList = async (
  setSelectedApplicantsList,
  subId,
  setLoading,
  setAlert,
  clearAlert,
  applicantsFilters,
  applicantsSelected,
  SelectAllStates
) => {
  setLoading(true);
  try {
    clearAlert();

    if (subId != null) {
      const url = `/submissions/${subId}/applicants`;
      const params = {
        applicantName: applicantsFilters?.applicant?.value?.fullName,
        acceptedYear: applicantsFilters.acceptedYear,
        applicationYear: applicantsFilters.applicationYear,
        applicantType: applicantsFilters.applicantType,
        newEntrant: applicantsFilters.newEntrant,
        grantEligible: applicantsFilters.grantEligible,
        department: applicantsFilters.departmentCode,
        isActive: true,
      };

      let page = 0;
      const pageSize = 500;
      let results = [];
      const { ALL_SELECTED, NONE_SELECTED } = SelectAllStates;

      const applicantIdsSelected = Object.keys(
        applicantsSelected?.individualSelections ?? []
      );

      if (
        applicantsSelected.selectAllState !== ALL_SELECTED &&
        applicantsSelected.length === 0
      ) {
        setSelectedApplicantsList(results);
        return;
      }

      let totalPages = 0;
      do {
        page = page + 1;

        const request = {
          url: url,
          params: { ...params, p: page, ps: pageSize, vw: "brief" },
        };
        const response = await axios(request);

        if (response.data.count > 0) {
          totalPages = response.data.totalPages || 0;

          const responseData = response.data.values.filter((applicant) => {
            if (applicantsSelected.selectAllState === ALL_SELECTED) {
              return true;
            }
            return applicantsSelected.selectAllState === NONE_SELECTED
              ? applicantIdsSelected.includes(applicant._links.self.href)
              : !applicantIdsSelected.includes(applicant._links.self.href);
          });

          results = [...results, ...responseData];
        }
      } while (page < totalPages);

      setSelectedApplicantsList(results);
    }
  } catch (error) {
    setAlert("error", error.message);
    //Basic error handling added here as the functionality does not require to display case specific error messages.
  } finally {
    setLoading(false);
  }
};
export const deleteSelectedApplicants = async (
  submissionId,
  setOpenSendInvitationDialog,
  setAlert,
  clearAlert,
  resetAllSelections,
  toggleApplicantsRemoved,
  setApplicantsFilterPreferences,
  applicantsFilterPreferences,
  applicantsSelected
) => {
  const { individualSelections, selectAllState } = applicantsSelected;

  const applicantIds = Object.keys(individualSelections).map(
    (link) => link.split("/").slice(-1)[0]
  );

  const filters =
    selectAllState !== SelectAllStates.NONE_SELECTED
      ? retrieveGetApplicantParams(applicantsFilterPreferences)
      : {};

  const data =
    selectAllState !== SelectAllStates.NONE_SELECTED
      ? { excludedIds: applicantIds }
      : { includedIds: applicantIds };

  try {
    clearAlert();
    const response = await axios.delete(
      `/submissions/${submissionId}/applicants`,
      {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        params: {
          ...filters,
        },
        data: data,
      }
    );
    if (response) {
      resetAllSelections();
      toggleApplicantsRemoved((prevState) => !prevState);
      setOpenSendInvitationDialog(false);
      const alertMessage = i18n.t(
        "removedApplicants.notification.applicantsRemoved",
        {
          numberOfApplicants: applicantIds.length ?? 0,
        }
      );
      setAlert("success", alertMessage, true);
      setApplicantsFilterPreferences({
        ...applicantsFilterPreferences,
        applicantValues: {
          ...applicantsFilterPreferences.applicantValues,
          renderCount:
            applicantsFilterPreferences.applicantValues.renderCount + 1,
        },
      });
    }
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};
