import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  tableContainerRoot: {
    width: "100%",
    minWidth: "100em",
    overflowX: "hidden",
  },
  applicantWrapper: {
    "& .searchName .MuiSvgIcon-root": {
      width: "18px",
      height: "18px",
      color: "white",
      background: theme.palette.primary.main,
      margin: "0% 9% 0 7%",
      verticalAlign: "bottom",
    },
    "& .disabledIconState.MuiSvgIcon-root": {
      color: theme.palette.grey[300],
    },
  },
  selectBoxHead: {
    "& .MuiSvgIcon-root": {
      color: theme.palette.common.white,
    },
    top: "-.2em",
  },
  disableRow: {
    color: theme.palette.grey[300],
    lineHeight: 1,
  },
  topDivStyled: {
    "& .MuiTableCell-head:last-child": {
      textAlign: "left",
      paddingLeft: "16px",
    },
    "& .MuiIconButton-root": {
      fontSize: "0.5rem",
      padding: "8px",
    },
  },
}));
