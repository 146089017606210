import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import {
  CloudDownload as MuiCloudDownloadIcon,
  Search as MuiSearchIcon,
} from "@material-ui/icons";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
import { TraineeDetails } from "./TraineeDetails/TraineeDetails";
import { useSetLoading } from "../../Utils/loadingState";
import { AutocompleteOption } from "../../Utils/Autocomplete";
import { ShowSnackBar } from "../../Utils/ActionTrigger";
import { RemoveTrainees } from "./DeleteTrainees";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  traineeTypeDropDown,
} from "../../constants";
/** Services */
import {
  setTraineeSubmissions,
  postAddTrainee,
  getTraineeList,
  getTraineeExportData,
} from "../../services/traineeService";
import { useTraineeContext } from "../../services/traineeContext";
import { getSubmission } from "../../services/submissionService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import {
  useTraineesSelectedContext,
  SelectAllStates,
} from "../../services/traineesSelectedContext";
/* Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useTraineeStyles } from "../Trainees/Trainees.styles";
import { useCommonStyles } from "../shared/common.styles";

export const Trainees = (props) => {
  const [submissionList, setSubmissionList] = useState([]);
  const [snackbar, setSnackbar] = useState({ show: false, message: "" });

  const { t } = useTranslation();
  const classes = useTraineeStyles();
  const commonClasses = useCommonStyles();
  const [refreshTraineesID, setRefreshedTraineeID] = useState(0);

  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { NONE_SELECTED } = SelectAllStates;
  const [, setError] = useState(false);
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [addOptionsloading, setAddOptionsloading] = useState(false);
  const [key, setKey] = useState(`add-${new Date().toString()}`);

  const [hasAddTraineeAccess, setHasAddTraineeAccess] = useState(false);

  const {
    traineePreferences,
    setTraineePreferences,
    updatePreferencesForSubmission,
  } = useTraineeContext();
  const { resetAllSelections } = useTraineesSelectedContext();
  const [summary, setSummary] = useState({});

  const [setTraineeList] = useState([]);
  const [trainees, setTrainees] = useState([]);
  const { alert, setAlert, clearAlert } = useAlertContext();
  const { traineesSelected } = useTraineesSelectedContext();
  const [openRemoveTraineesDialog, setOpenRemoveTraineesDialog] =
    useState(false);
  const [traineesRemoved, toggleTraineesRemoved] = useState(false);
  let timeoutID; // ID for API debounce timeout

  useEffect(
    () => {
      setTraineeSubmissions(setSubmissionList, setAlert, clearAlert);
    },
    // eslint-disable-next-line
    []
  );

  //New getSubmission implementation
  const id = getSelectedSubmission();
  useEffect(() => {
    resetAllSelections();
    if (id)
      getSubmission(
        id,
        setHasAddTraineeAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Trainee",
        getSelectedSubmissionName
      );
    // eslint-disable-next-line
  }, [id, setHasAddTraineeAccess]);
  const handleChangeSubmission = () => {
    // To reload the Filters on the basis of Updated Submission Id
    props.handleSubmissionUpdate();
    const submission = getSelectedSubmission();
    updatePreferencesForSubmission(submission);
  };

  const handleChangeAutoComplete = (event) => {
    const traineeVal = event.target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(() => {
      if (traineeVal.length >= AUTOCOMPLETE_MIN_INPUT) {
        setTrainees([]);

        getTraineeList(
          traineeVal,
          15,
          setTrainees,
          setAlert,
          clearAlert,
          setAddOptionsloading,
          id,
          "trainees"
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };

  const handleTraineeSelection = (e, value) => {
    setSnackbar({ show: false, message: "" });
    if (value) {
      setError(false);
      postAddTrainee(
        setSnackbar,
        getSelectedSubmission(),
        value.value,
        setRefreshedTraineeID,
        setTraineePreferences,
        traineePreferences,
        setAlert,
        clearAlert,
        setLoadingItems,
        setKey
      );
    }
  };

  const handleExport = () => {
    getTraineeExportData(
      getSelectedSubmission(),
      traineePreferences,
      summary.totalCount,
      setAlert,
      clearAlert
    );
  };
  const disableAllTrainees = (e) => {
    clearAlert();
    setOpenRemoveTraineesDialog(true);
  };

  const displayText = (option) => {
    const traineeStartYear = new Date(
      option.value?.appointmentStartDate
    ).getFullYear();
    const traineeEndYear = new Date(
      option.value?.appointmentEndDate
    ).getFullYear();

    const displaytextParts = [];

    displaytextParts.push(t(traineeTypeDropDown[option.value.traineeType]));
    if (option.value.department) {
      displaytextParts.push(option.value.department.name);
    }
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    if (traineeStartYear && traineeEndYear) {
      return `${displaytextParts.join(
        ", "
      )} (${traineeStartYear}-${traineeEndYear})`;
    }

    return displaytextParts.join(", ");
  };

  return (
    <>
      {openRemoveTraineesDialog && (
        <RemoveTrainees
          openRemoveTraineesDialog={openRemoveTraineesDialog}
          setOpenRemoveTraineesDialog={setOpenRemoveTraineesDialog}
          toggleTraineesRemoved={toggleTraineesRemoved}
          setLoading={setLoadingItems}
          setTraineeList={setTraineeList}
          totalCount={summary.totalActiveCount}
        />
      )}

      <MuiGrid container wrap="nowrap" justify="flex-start" direction="column">
        {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
        {alert.exists && (
          <MuiGrid item>
            <ASAlert />
          </MuiGrid>
        )}
        <MuiGrid item classes={{ item: commonClasses.autocompleteWrapper }}>
          <SubmissionSelect
            submissionList={submissionList}
            handleChangeSubmission={handleChangeSubmission}
          />
        </MuiGrid>

        <MuiGrid item classes={{ item: classes.inputPanelWrapper }}>
          <MuiGrid container direction="row" justify="space-between">
            <MuiGrid item className={commonClasses.autocompleteWrapper}>
              <MuiAutocomplete
                key={key}
                blurOnSelect={true}
                clearOnBlur={true}
                openOnFocus={false}
                value={null}
                id="add-trainee-combo"
                disabled={!hasAddTraineeAccess || loading}
                loading={addOptionsloading}
                options={trainees.count ? trainees.values : []}
                filterOptions={(option, state) => option}
                getOptionDisabled={(option) => option.isDummy}
                disabledItemsFocusable={true}
                getOptionLabel={(option) => option.value.fullName}
                noOptionsText={t("globals.autocomplete.helperText")}
                defaultValue={null}
                onChange={(e, value) => handleTraineeSelection(e, value)}
                onClose={() => {
                  setTrainees([]);
                }}
                popupIcon={<MuiSearchIcon />}
                classes={{ root: commonClasses.autocompleteRoot }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t("trainee.mainView.addTrainee")}
                    variant="outlined"
                    onChange={handleChangeAutoComplete}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addOptionsloading || loading ? (
                            <MuiCircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(option, { inputValue }) => (
                  <AutocompleteOption
                    classes={classes}
                    option={{ ...option }}
                    inputValue={inputValue}
                  >
                    {displayText(option)}
                  </AutocompleteOption>
                )}
              />
            </MuiGrid>
            <MuiGrid item classes={{ item: classes.buttonWrapper }}>
              <MuiButton
                variant="outlined"
                classes={{ root: classes.exportButton }}
                onClick={handleExport}
                title={summary.exportMessage}
              >
                <MuiCloudDownloadIcon />
                &nbsp;{t("faculty.export.exportButtonText")}
              </MuiButton>
              {
                <span>
                  <MuiButton
                    variant="contained"
                    className={classes.button}
                    disabled={
                      (traineesSelected.selectAllState === NONE_SELECTED &&
                        Object.keys(
                          traineesSelected?.individualSelections ?? []
                        ).length === 0) ||
                      (traineesSelected.selectAllState !== NONE_SELECTED &&
                        Object.keys(
                          traineesSelected?.individualSelections ?? []
                        ).length === summary.totalActiveCount)
                    }
                    onClick={(e) => disableAllTrainees(e)}
                  >
                    &nbsp;&nbsp;
                    {t("trainee.mainView.removeAll.label")}
                  </MuiButton>
                </span>
              }
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>
        <MuiGrid item>
          <TraineeDetails
            refreshTrainees={refreshTraineesID}
            filterData={props.filterData}
            history={props.history}
            setAlert={setAlert}
            clearAlert={clearAlert}
            traineesRemoved={traineesRemoved}
            hasAddTraineeAccess={hasAddTraineeAccess}
            summary={summary}
            setSummary={setSummary}
            setTraineeList={setTraineeList}
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
