import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Grid as MuiGrid, Button as MuiButton } from "@material-ui/core";
import { CloudDownload as MuiCloudDownloadIcon } from "@material-ui/icons";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
import { useSetLoading } from "../../Utils/loadingState";
import { GrantDetails } from "./GrantDetails";
/** Services */
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { useGrantsContext } from "../../services/grantsContext";
import {
  getSubmissionsList,
  getGrantsExportData,
} from "../../services/grantsService";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
/** Styles */
import { useStyles } from "./Grants.styles";
import { useCommonStyles } from "../shared/common.styles";

export const Grants = (props) => {
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const { t } = useTranslation();
  const [submissionList, setSubmissionList] = useState([]);
  const [getSelectedSubmission] = useSelectedSubmission().value;
  const [summary, setSummary] = useState({});
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();

  const { grantsPreference, updatePreferencesForSubmission } =
    useGrantsContext();

  const { alert, setAlert, clearAlert } = useAlertContext();

  useEffect(() => {
    getSubmissionsList(
      setSubmissionList,
      setLoadingSubmission,
      setAlert,
      clearAlert
    );
    // eslint-disable-next-line
  }, []);

  const handleChangeSubmission = () => {
    // To reload the Filters on the basis of Updated Submission Id
    props.handleSubmissionUpdate();
    const submission = getSelectedSubmission();
    updatePreferencesForSubmission(submission);
  };

  // *** Export CSV file of Table Data ***
  const handleExport = () => {
    getGrantsExportData(
      getSelectedSubmission(),
      grantsPreference,
      summary.totalCount
    );
  };

  return (
    <MuiGrid container justify="flex-start" direction="column">
      {alert.exists && (
        <MuiGrid item>
          <ASAlert />
        </MuiGrid>
      )}
      <MuiGrid item classes={{ item: commonClasses.autocompleteWrapper }}>
        <SubmissionSelect
          submissionList={submissionList}
          handleChangeSubmission={handleChangeSubmission}
        />
      </MuiGrid>
      <MuiGrid
        container
        item
        justify="flex-end"
        classes={{ item: classes.inputPanelWrapper }}
      >
        <MuiButton
          variant="outlined"
          classes={{ root: classes.button }}
          onClick={handleExport}
          title={summary.exportMessage}
        >
          <MuiCloudDownloadIcon />
          &nbsp;{t("faculty.export.exportButtonText")}
        </MuiButton>
      </MuiGrid>
      <MuiGrid item>
        <GrantDetails
          filterData={props.filterData}
          history={props.history}
          setAlert={setAlert}
          clearAlert={clearAlert}
          loading={loading}
          setLoading={setLoadingItems}
          summary={summary}
          setSummary={setSummary}
        />
      </MuiGrid>
    </MuiGrid>
  );
};
