import { useEffect, useState, createContext, useContext } from "react";
import { useHistory } from "react-router-dom";
import { usePagingSortingContext } from "./pagingSortingContext";
import { useBrowserStorage } from "./StorageService/StorageHelper";
import { isActiveOptions, STORAGE_NAMES, STORAGE_TYPE } from "../constants";

export const TraineeContext = createContext();

export const TraineeProvider = ({ children }) => {
  const history = useHistory();
  const { traineePagingSortingStored, setTraineePagingSortingStored } =
    usePagingSortingContext().value;

  const { getItem, saveItem } = useBrowserStorage(STORAGE_TYPE.SESSION);
  const filterValues = getItem(
    STORAGE_NAMES.TGDS_TRAINEES_DASHBOARD_FILTERS,
    Object
  );
  const [traineePreferences, setTraineePreferencesInternal] = useState(
    initialTraineeState({
      location: history.location,
      pageSort: traineePagingSortingStored,
      filterValues,
    })
  );

  const setTraineePreferences = (preferences) => {
    preferences = preferences || {};

    const locPageSort = {
      pagination: preferences.traineeValues?.pagination || {},
      sort: preferences.traineeValues?.sort || {},
    };
    setTraineePagingSortingStored(locPageSort);
    setTraineePreferencesInternal(preferences);
    saveItem(STORAGE_NAMES.TGDS_TRAINEES_DASHBOARD_FILTERS, preferences);
  };

  useEffect(() => {
    setTraineePagingSortingStored({
      pagination: traineePreferences.traineeValues?.pagination || {},
      sort: traineePreferences.traineeValues?.sort || {},
    });
  }, [setTraineePagingSortingStored, traineePreferences]);

  const updatePreferencesForSubmission = (selectedSubmission) => {
    const initState = initialTraineeState({});
    setTraineePreferences({
      ...initState,
      traineeValues: {
        ...initState.traineeValues,
        submission: selectedSubmission,
      },
    });
  };

  return (
    <TraineeContext.Provider
      value={{
        traineePreferences,
        setTraineePreferences,
        updatePreferencesForSubmission,
      }}
    >
      {children}
    </TraineeContext.Provider>
  );
};

export const initialTraineeState = ({ location, pageSort, filterValues }) => {
  pageSort = pageSort || {};
  const { filters, pagination, sort } = filterValues?.traineeValues ?? {};

  return {
    traineeValues: {
      pagination: {
        page: pagination?.page || 0,
        pageSize: pagination?.pageSize || 0,
        ...pageSort.pagination,
      },
      sort: {
        order: sort?.order || "",
        orderBy: sort?.orderBy || "",
        ...pageSort.sort,
      },
      renderCount: 0,
      submission: 0,
      filters: {
        traineeName: filters?.traineeName || "",
        departmentName: filters?.departmentName || "",
        departmentCode: filters?.departmentCode || "",
        facultyName: filters?.facultyName || "",
        traineeType: filters?.traineeType || "",
        currentTrainee: filters?.currentTrainee || "",
        trainingStartDateFrom: filters?.trainingStartDateFrom || "",
        trainingStartDateTo: filters?.trainingStartDateTo || "",
        trainingEndDateFrom: filters?.trainingEndDateFrom || "",
        trainingEndDateTo: filters?.trainingEndDateTo || "",
        isActive: filters?.isActive ?? isActiveOptions[0],
      },
    },
  };
};

export const useTraineeContext = () => {
  return useContext(TraineeContext);
};
