import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  TextField as MuiTextField,
  Button as MuiButton,
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
} from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import {
  AddCircle as MuiAddCircleIcon,
  CloudDownload as MuiCloudDownloadIcon,
  Search as MuiSearchIcon,
} from "@material-ui/icons";
/** Custom Components */
import { ApplicantsDetails } from "./ApplicantsDetails";
import { SubmissionSelect } from "../UI/SubmissionSelect/SubmissionSelect";
import { useSetLoading } from "../../Utils/loadingState";
import { AutocompleteOption } from "../../Utils/Autocomplete";
import { ShowSnackBar } from "../../Utils/ActionTrigger";
import {
  AUTOCOMPLETE_DEBOUNCE_DELAY,
  AUTOCOMPLETE_MIN_INPUT,
  traineeTypeDropDown,
} from "../../constants";
/** Services */
import {
  getApplicantList,
  getApplicantsExportData,
  getSubmissionsList,
  postSaveApplicant,
} from "../../services/applicantService";
import { useSelectedSubmission } from "../../services/selectedSubmissionService";
import { getSubmission } from "../../services/submissionService";
import {
  useApplicantsSelectedContext,
  SelectAllStates,
} from "../../services/applicantsSelectedContext";
import { useApplicantsFilterContext } from "../../services/applicantContext";
/** Context and Component to show the error on UI */
import { useAlertContext, ASAlert } from "@stanford-tds/as-components";
import { RemoveApplicants } from "./RemoveApplicants";
/** Styles */
import { useStyles } from "./Applicants.styles";
import { useCommonStyles } from "../shared/common.styles";

export const Applicants = (props) => {
  const [submissionList, setSubmissionList] = useState([]);
  const [snackbar, setSnackbar] = useState({ show: false, message: "" });

  const { t } = useTranslation();
  const [refreshApplicantID, setRefreshedApplicantID] = useState(0);
  const [getSelectedSubmission, setSelectedSubmission] =
    useSelectedSubmission().value;
  const [getSelectedSubmissionName] = useSelectedSubmission().name;
  const { NONE_SELECTED } = SelectAllStates;
  const [hasAddApplicantAccess, setHasAddApplicantAccess] = useState(false);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [applicant, setApplicants] = useState([]);
  const [summary, setSummary] = useState({});
  const [setApplicantList] = useState([]);
  const {
    applicantsFilterPreferences,
    updatePreferencesForSubmission,
    setApplicantsFilterPreferences,
  } = useApplicantsFilterContext();
  const { resetAllSelections } = useApplicantsSelectedContext();
  const { alert, setAlert, clearAlert } = useAlertContext();
  const { applicantsSelected } = useApplicantsSelectedContext();
  const [openRemoveApplicantsDialog, setOpenRemoveApplicantsDialog] =
    useState(false);
  const [applicantsRemoved, toggleApplicantsRemoved] = useState(false);
  const [loading, setLoadingSubmission, setLoadingItems] = useSetLoading();
  const [addOptionsloading, setAddOptionsloading] = useState(false);
  const [key, setKey] = useState(`add-${new Date().toString()}`);

  let timeoutID; // ID for API debounce timeout

  useEffect(() => {
    getSubmissionsList(setSubmissionList, setAlert, clearAlert);
    // eslint-disable-next-line
  }, []);
  const id = getSelectedSubmission();
  useEffect(() => {
    resetAllSelections();
    if (id)
      getSubmission(
        id,
        setHasAddApplicantAccess,
        setLoadingSubmission,
        setAlert,
        clearAlert,
        "Applicant",
        getSelectedSubmissionName
      );
    // eslint-disable-next-line
  }, [getSelectedSubmission(), setHasAddApplicantAccess]);

  const handleExport = () => {
    getApplicantsExportData(
      getSelectedSubmission(),
      applicantsFilterPreferences,
      summary.totalCount,
      setAlert,
      clearAlert
    );
  };

  /* Update Submission and Re-render the Page,
   * Also reload the Filters on the basis of Updated Submission Id
   */
  const handleChangeSubmission = () => {
    let selectedSubmission = getSelectedSubmission();
    setSelectedSubmission(selectedSubmission);
    props.handleSubmissionUpdate();
    // getSubmissionAccess();
    updatePreferencesForSubmission(selectedSubmission);
  };

  const handleApplicantAutocompleteChange = (event) => {
    const applicantVal = event.target.value.trim();

    clearTimeout(timeoutID); // Cancel current debounce timer

    // Start debounce timer and call API when timer finishes
    timeoutID = setTimeout(() => {
      if (applicantVal.length >= AUTOCOMPLETE_MIN_INPUT) {
        setApplicants([]);

        getApplicantList(
          applicantVal,
          5,
          setApplicants,
          setAlert,
          clearAlert,
          setAddOptionsloading,
          id
        );
      }
    }, AUTOCOMPLETE_DEBOUNCE_DELAY);
  };

  const handleApplicantSelection = (e, value) => {
    setSnackbar({ show: false, message: "" });
    if (value) {
      postSaveApplicant(
        setSnackbar,
        getSelectedSubmission(),
        value.value,
        setRefreshedApplicantID,
        setAlert,
        clearAlert,
        setLoadingItems,
        setKey,
        applicantsFilterPreferences,
        setApplicantsFilterPreferences
      );
    }
  };

  let createApplicantButton = null;
  if (hasAddApplicantAccess) {
    createApplicantButton = (
      <div className={classes.buttonWrapper}>
        <Link to={`/applicants/${id}/new`}>
          <MuiButton variant="contained" color="primary">
            <MuiAddCircleIcon />
            &nbsp;&nbsp;{t("applicants.mainView.createApplicant")}
          </MuiButton>
        </Link>
      </div>
    );
  }
  const removeAllApplicants = (e) => {
    clearAlert();
    setOpenRemoveApplicantsDialog(true);
  };

  const displayText = (option) => {
    const displaytextParts = [];

    displaytextParts.push(t(traineeTypeDropDown[option.value.traineeType]));
    if (option.value.department) {
      displaytextParts.push(option.value.department.name);
    }
    if (option.value.sunetId) {
      displaytextParts.push(option.value.sunetId);
    }

    if (option.value.applicationYear) {
      return `${displaytextParts.join(", ")} (${option.value.applicationYear})`;
    }

    return displaytextParts.join(", ");
  };

  return (
    <>
      {openRemoveApplicantsDialog && (
        <RemoveApplicants
          openRemoveApplicantsDialog={openRemoveApplicantsDialog}
          setOpenRemoveApplicantsDialog={setOpenRemoveApplicantsDialog}
          toggleApplicantsRemoved={toggleApplicantsRemoved}
          totalCount={summary.totalActiveCount}
        />
      )}
      <MuiGrid container justify="flex-start" direction="column">
        {snackbar.show && <ShowSnackBar message={t(snackbar.message)} />}
        {alert.exists && (
          <MuiGrid item>
            <ASAlert />
          </MuiGrid>
        )}
        <MuiGrid item classes={{ item: commonClasses.autocompleteWrapper }}>
          <SubmissionSelect
            submissionList={submissionList}
            handleChangeSubmission={handleChangeSubmission}
          />
        </MuiGrid>
        <MuiGrid item classes={{ item: classes.inputPanelWrapper }}>
          <MuiGrid container direction="row" justify="space-between">
            <MuiGrid item className={commonClasses.autocompleteWrapper}>
              <MuiAutocomplete
                key={key}
                blurOnSelect={true}
                clearOnBlur={true}
                openOnFocus={false}
                value={null}
                id="add-applicant-combo"
                disabled={!hasAddApplicantAccess || loading}
                loading={addOptionsloading}
                options={applicant.count > 0 ? applicant.values : []}
                filterOptions={(option, state) => option}
                getOptionDisabled={(option) => option.isDummy}
                disabledItemsFocusable={true}
                getOptionLabel={(option) => option.value && option.displayText}
                noOptionsText={t("globals.autocomplete.helperText")}
                defaultValue={null}
                onChange={(e, value) => handleApplicantSelection(e, value)}
                onClose={() => {
                  setApplicants([]);
                }}
                popupIcon={<MuiSearchIcon />}
                classes={{ root: commonClasses.autocompleteRoot }}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    label={t("applicants.mainView.addApplicant")}
                    variant="outlined"
                    onChange={handleApplicantAutocompleteChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {addOptionsloading || loading ? (
                            <MuiCircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
                renderOption={(option, { inputValue }) => (
                  <AutocompleteOption
                    classes={classes}
                    option={option}
                    inputValue={inputValue}
                  >
                    {displayText(option)}
                  </AutocompleteOption>
                )}
              />
            </MuiGrid>
            <MuiGrid item className={classes.buttonWrapper}>
              <MuiButton
                variant="outlined"
                classes={{ root: classes.exportButton }}
                onClick={handleExport}
                title={summary.exportMessage}
              >
                <MuiCloudDownloadIcon />
                &nbsp;{t("faculty.export.exportButtonText")}
              </MuiButton>

              {createApplicantButton}
              <MuiGrid item className={classes.buttonWrapper}>
                {
                  <MuiButton
                    variant="contained"
                    className={classes.button}
                    disabled={
                      (applicantsSelected.selectAllState === NONE_SELECTED &&
                        Object.keys(
                          applicantsSelected?.individualSelections ?? []
                        ).length === 0) ||
                      (applicantsSelected.selectAllState !== NONE_SELECTED &&
                        Object.keys(
                          applicantsSelected?.individualSelections ?? []
                        ).length === summary.totalActiveCount)
                    }
                    onClick={(e) => removeAllApplicants(e)}
                  >
                    &nbsp;&nbsp;
                    {t("applicants.mainView.removeAll.label")}
                  </MuiButton>
                }
              </MuiGrid>
            </MuiGrid>
          </MuiGrid>
        </MuiGrid>

        <MuiGrid item>
          <ApplicantsDetails
            refreshApplicants={refreshApplicantID}
            history={props.history}
            setAlert={setAlert}
            clearAlert={clearAlert}
            applicantsRemoved={applicantsRemoved}
            loading={loading}
            setLoading={setLoadingItems}
            summary={summary}
            setSummary={setSummary}
            setApplicantList={setApplicantList}
          />
        </MuiGrid>
      </MuiGrid>
    </>
  );
};
