import axios from "axios";
import i18n from "../i18n";
import * as qs from "qs";
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from "../constants";
import { moreItemsOption } from "../Utils/Autocomplete";
import { SelectAllStates } from "./traineesSelectedContext";
import { MAX_CSV_EXPORT_RECORDS } from "../constants";

let timeoutId;

export const setTraineeSubmissions = async (
  setSubmissionList,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const request = {
      url: "/submissions",
      params: {
        vw: "brief",
        ps: 500,
      },
    };
    const response = await axios(request);

    if (response.data.count) {
      setSubmissionList(response.data.values);
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

export const getTraineeList = async (
  val,
  yearsToGoBack,
  setTrainees,
  setAlert,
  clearAlert,
  setOptionsLoading,
  submissionId,
  contextValue
) => {
  try {
    clearAlert();
    setOptionsLoading(true);
    const fetchSize = 50;
    if (val) {
      const request = {
        url: "/trainees/autocomplete",
        params: {
          context: contextValue,
          value: val,
          size: fetchSize,
          yearToGoBack: yearsToGoBack,
          submissionId,
        },
        paramsSerializer: {
          serialize: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        },
      };
      const response = await axios(request);

      if (response) {
        const traineeList = response.data;
        // pushing helper text as an option when the options are greater than or equal to fetchSize
        if (traineeList.values && traineeList.values.length >= fetchSize) {
          traineeList.values.push(moreItemsOption(fetchSize));
        }
        setTrainees(traineeList || []);
      } else {
        setTrainees([]);
      }
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setOptionsLoading(false);
  }
};

const retrieveGetTraineeParams = (context) => {
  const { filters, pagination, sort } = context.traineeValues;
  const uniId = filters.traineeName?.value?.universityId;
  const facultyId = filters.facultyName?.value?.universityId;
  const traineeTypeCode = filters.traineeType;
  const {
    currentTrainee,
    departmentCode,
    trainingStartDateFrom,
    trainingStartDateTo,
    trainingEndDateFrom,
    trainingEndDateTo,
    isActive,
  } = filters;
  const { page, pageSize } = pagination;
  const { order, orderBy } = sort;

  return {
    ...(uniId && { traineeUniversityId: uniId }),
    ...(departmentCode && { department: departmentCode.code }),
    ...(facultyId && { facultyUniversityId: facultyId }),
    ...(traineeTypeCode && { traineeType: traineeTypeCode }),
    ...(currentTrainee && { currentTrainee }),
    ...(trainingStartDateFrom && { trainingStartDateFrom }),
    ...(trainingStartDateTo && { trainingStartDateTo }),
    ...(trainingEndDateFrom && { trainingEndDateFrom }),
    ...(trainingEndDateTo && { trainingEndDateTo }),
    isActive: isActive && isActive?.value,
    p: +page + 1,
    ps: pageSize || 10,
    s: `${orderBy ? `${orderBy}` : `trainee`}:${order ? `${order}` : `asc`}`,
  };
};

/* New API Service level implementation */
export const getAllTraineeList = async (
  setTraineeList,
  setSummary,
  subId,
  setLoading,
  setAlert,
  clearAlert,
  setError,
  context,
  history,
  setMetaData,
  getSelectedSubmissionName
) => {
  try {
    setLoading(true);
    clearAlert();
    let request = "";
    let response = "";
    const requestParams = retrieveGetTraineeParams(context);

    if (subId) {
      request = {
        url: `/submissions/${subId}/trainees`,
        params: requestParams,
      };

      response = await axios(request);
      const summary = { totalCount: 0, totalActiveCount: 0 };
      if (requestParams.isActive === true) {
        summary.totalActiveCount = response.data.totalCount;
      } else if (requestParams.isActive === false) {
        summary.totalActiveCount = 0;
      } else {
        const requestActive = {
          url: `/submissions/${subId}/trainees`,
          params: { ...requestParams, isActive: true },
        };

        const responseActive = await axios(requestActive);
        summary.totalActiveCount = responseActive.data.totalCount;
      }

      setMetaData(response.data.meta);
      setTraineeList(response.data);
      const exportMessage =
        response.data.totalCount > MAX_CSV_EXPORT_RECORDS
          ? i18n.t("globals.metaDataInfo.exportTitleTooMany")
          : i18n.t("globals.metaDataInfo.exportTitleNormal");
      setSummary({
        ...summary,
        totalCount: response.data.totalCount,
        exportMessage: exportMessage,
      });
    } else {
      setMetaData(false);
      setTraineeList([]);
      setSummary({ totalCount: 0 });
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
  }
};

/**
 * API call to Export CSV
 */
export const getTraineeExportData = async (
  subId,
  context,
  totalCount,
  setAlert,
  clearAlert
) => {
  try {
    clearAlert();
    const requestParams = retrieveGetTraineeParams(context);
    // Create queryString to be appended to URL Path
    clearAlert();
    let request = "";
    let response = "";

    if (subId != null) {
      request = {
        url: `/submissions/${subId}/trainees`,
        params: {
          ...requestParams,
          p: 1,
          ps: totalCount,
        },
        responseType: "blob",
        headers: {
          Accept: "application/vnd.ms-excel",
          "Content-type": "application/vnd.ms-excel",
        },
      };

      response = await axios(request);

      if (response.data) {
        const currentDate = new Date();
        const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
        const date = ("0" + currentDate.getDate()).slice(-2);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export_Trainees_${currentDate.getFullYear()}${month}${date}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      }
    }
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  }
};

/**
 * Returns Pre-fetched filters values for the Selected Submission Id
 *
 * @param {string} traineeFilterUrlPath - Path String/Query String, for the field data endpoint
 * @return Filter Dropdown/Date Values of the Response Data from Endpoint
 */

export const getTraineeFilters = async (
  setProgramList,
  setTraineeDates,
  setTraineeType,
  selectedSubmission,
  setAlert,
  clearAlert,
  getSelectedSubmissionName
) => {
  try {
    clearAlert();
    if (selectedSubmission) {
      const response = await axios({
        url: `/submissions/${selectedSubmission}/trainees/filters`,
      });

      const { traineeDepartmentsOrPrograms, traineeTypes, trainingPeriod } =
        response.data;

      setProgramList(traineeDepartmentsOrPrograms.values ?? []);
      setTraineeDates(trainingPeriod);
      setTraineeType(traineeTypes.values ?? []);
    }
  } catch (error) {
    // check if the error is due to submission not found
    if (
      error.status === 404 &&
      (error.code === "RESOURCE_NOT_FOUND" ||
        error.code === "RESOURCE_UNKNOWN") &&
      error.response?.data?.detail?.properties[0]?.resource === "Submission"
    ) {
      const submissionName = getSelectedSubmissionName();
      // set alert message for submission not found
      setAlert(
        "error",
        i18n.t("submission.create.notification.submissionNotFoundError", {
          submissionName,
        })
      );
    } else {
      // set alert message for other errors
      setAlert("error", error.message);
    }
  }
};

/**
 * Returns Trainee values to be Edited, for the Selected Submission Id
 **/

export const getTraineeDetailsById = async (
  setTraineeFormValues,
  setLoading,
  traineeId,
  submissionId,
  setAlert,
  clearAlert
) => {
  try {
    setLoading(true);
    clearAlert();
    const response = await axios({
      url: `/submissions/${submissionId}/trainees/${traineeId}`,
    });
    setTraineeFormValues(response.data);
  } catch (error) {
    //Basic error handling added here as the functionality does not require to display case specific error messages.
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

/**
 * Returns Citizenship values for the Trainee, for the Selected Submission Id
 * @param {string} citizenshipInputValue - Citizenship Value in Input
 * @return Array of Objects of Citizenship Dropdown Values
 */

export const getEditTraineeCitizenshipValues = (
  citizenshipInputValue,
  setCitizenshipList
) => {
  if (citizenshipInputValue.length < 1) {
    return;
  }
  clearTimeout(timeoutId);
  timeoutId = setTimeout(async () => {
    const response = await axios({
      url: `countries/autocomplete`,
      params: { value: citizenshipInputValue },
    });

    const citizenshipValues =
      (response.data.values &&
        response.data.values.map((citizenshipObj) => citizenshipObj.value)) ||
      [];
    setCitizenshipList(citizenshipValues);
  }, AUTOCOMPLETE_DEBOUNCE_DELAY);
};

/**
 * Returns Success/Error Response Data for the Update Trainee API call
 *
 * @param {string} submissionId - Selected Submission ID
 * @param {string} traineeId - ID of Trainee to be updated
 * @param {string} values  - Data to be Updated for the Trainee
 * @return Success/Error Response for the Update Trainee API call
 */

export const putTraineeDetailsByIdUpdate = async (
  setEmailFieldError,
  setCommonsIdFieldError,
  submissionId,
  traineeId,
  values,
  props,
  setAlert,
  clearAlert
) => {
  /* Do not mutate original Formik.values object,
   * Created a new object (with a new reference) to submit the form values
   *  This is needed, to keep the original Form values intact,
   *  as in case of any API Error on Form Submit,
   *  original Form state should be available,as it was before Submit.
   */

  const editTraineeFormValues = Object.assign({}, values);

  // ** Send univId and sunetId (if faculty has a sunetId) for the Trainee Faculty Data **
  editTraineeFormValues.submissionTraineeFaculty =
    editTraineeFormValues.submissionTraineeFaculty.map((faculty) => ({
      // TODO : Verify if univId is needed in the API call
      univId:
        faculty.value?.universityId || faculty.univId || faculty.universityId,
      universityId:
        faculty.value?.universityId || faculty.univId || faculty.universityId,
      ...((faculty.value?.sunetId || faculty.sunetId) && {
        sunetId: faculty.value?.sunetId || faculty.sunetId,
      }),
    }));

  try {
    clearAlert();
    const response = await axios.put(
      `/submissions/${submissionId}/trainees/${traineeId}`,
      editTraineeFormValues
    );

    // **** Redirect to /trainees on Successful form Submit ****
    const alertMessage = i18n.t("trainee.notification.updateTrainee", {
      traineeFullName: `"${response.data.trainee.fullName.trim()}"`,
    });
    props.history.push({
      pathname: "/trainees",
    });
    setAlert("success", alertMessage, true);
  } catch (error) {
    if (error.status === 400) {
      setEmailFieldError({ value: values.stanfordEmail, isError: true });
    } else if (error.status === 409) {
      setCommonsIdFieldError({ value: values.commonsId, isError: true });
    } else {
      setAlert("error", error.message);
    }
  }
};

export const postAddTrainee = async (
  setSnackbar,
  selectedSubmission,
  saveTrainee,
  setRefreshedTraineeID,
  setTraineePreferences,
  traineePreferences,
  setAlert,
  clearAlert,
  setLoading,
  setKey
) => {
  try {
    clearAlert();
    if (saveTrainee) {
      setLoading(true);

      const request = {
        method: "post",
        url: `/submissions/${selectedSubmission}/trainees`,
        data: saveTrainee,
      };
      const response = await axios(request);

      if (response && response.status === 201) {
        setSnackbar({
          show: true,
          message: "trainee.mainView.addTraineeMessage",
        });

        setRefreshedTraineeID(response.data.trainee.universityId);
        setTraineePreferences({
          ...traineePreferences,
          traineeValues: {
            ...traineePreferences.traineeValues,
            renderCount: traineePreferences.traineeValues.renderCount + 1,
          },
        });
      }
    }
  } catch (error) {
    if (
      error.status === 404 &&
      error.code === "RESOURCE_NOT_FOUND" &&
      error.response.data.detail.properties.some(
        (property) => property.resource === "SubmissionTrainee"
      )
    ) {
      setAlert("error", i18n.t(`trainee.mainView.notFound`));
    } else if (
      error.status === 409 &&
      error.code === "RESOURCE_EXISTS" &&
      error.response.data.detail.properties.some(
        (property) => property.resource === "SubmissionTrainee"
      )
    ) {
      setAlert("error", i18n.t(`trainee.mainView.duplicateTrainee`));
    } else if (
      error.status === 400 &&
      error.code === "INVALID_VALUE" &&
      error.response.data.detail.properties.some(
        (property) => property.resource === "SubmissionTrainee"
      )
    ) {
      setAlert("error", i18n.t(`trainee.mainView.ineligibleTrainee`));
    } else {
      setAlert("error", error.message);
    }
  } finally {
    setLoading(false);
    setKey(`add-${new Date().toString()}`);
  }
};

/**
 * Patch call to remove or add trainee
 */
export const removeAddTraineeById = async (
  selectedSubmission,
  traineeId,
  traineeData,
  setSnackbar,
  setAlert,
  clearAlert,
  setLoading,
  traineePreferences,
  setTraineePreferences
) => {
  try {
    setLoading(true);
    clearAlert();
    const request = {
      method: "PATCH",
      url: `/submissions/${selectedSubmission}/trainees/${traineeId}`,
      data: { ...traineeData, isActive: !traineeData.isActive },
    };
    let response = await axios(request);

    traineeData.isActive = response.data.isActive;
    setSnackbar(true);
    setTraineePreferences({
      ...traineePreferences,
      traineeValues: {
        ...traineePreferences.traineeValues,
        renderCount: traineePreferences.traineeValues.renderCount + 1,
      },
    });
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    setLoading(false);
  }
};

export const getAllSelectedTraineeList = async (
  setSelectedTraineesList,
  subId,
  setLoading,
  setAlert,
  clearAlert,
  traineesFilters,
  traineesSelected,
  SelectAllStates
) => {
  const currentTrainee = traineesFilters.currentTrainee;
  const departmentCode = traineesFilters.departmentCode?.code;
  const facultyId = traineesFilters.facultyName?.value?.universityId;
  const traineeTypeCode = traineesFilters.traineeType;
  const uniId = traineesFilters.traineeName?.value?.universityId;
  const {
    trainingStartDateFrom,
    trainingStartDateTo,
    trainingEndDateFrom,
    trainingEndDateTo,
  } = traineesFilters;
  setLoading(true);

  try {
    clearAlert();

    if (subId != null) {
      const url = `/submissions/${subId}/trainees`;
      const params = {
        traineeUniversityId: uniId,
        facultyUniversityId: facultyId,
        traineeType: traineeTypeCode,
        department: departmentCode,
        currentTrainee: currentTrainee,
        trainingStartDateFrom,
        trainingStartDateTo,
        trainingEndDateFrom,
        trainingEndDateTo,
        isActive: true,
      };

      let page = 0;
      const pageSize = 500;
      let results = [];
      const { ALL_SELECTED, NONE_SELECTED } = SelectAllStates;

      const traineeIdsSelected = Object.keys(
        traineesSelected?.individualSelections ?? []
      );

      if (
        traineesSelected.selectAllState !== ALL_SELECTED &&
        traineeIdsSelected.length === 0
      ) {
        setSelectedTraineesList(results);
        return;
      }

      let totalPages = 0;
      do {
        page = page + 1;

        const request = {
          url: url,
          params: { ...params, p: page, ps: pageSize, vw: "brief" },
        };
        const response = await axios(request);

        if (response.data.count > 0) {
          totalPages = response.data.totalPages || 0;

          const responseData = response.data.values.filter((trainee) => {
            if (traineesSelected.selectAllState === ALL_SELECTED) return true;

            return traineesSelected.selectAllState === NONE_SELECTED
              ? traineeIdsSelected.includes(trainee._links.self.href)
              : !traineeIdsSelected.includes(trainee._links.self.href);
          });

          results = [...results, ...responseData];
        }
      } while (page < totalPages);
      setSelectedTraineesList(results);
    }
  } catch (error) {
    setAlert("error", error.message);
    //Basic error handling added here as the functionality does not require to display case specific error messages.
  } finally {
    setLoading(false);
  }
};

export const removeSelectedTrainees = async (
  submissionId,
  setOpenSendInvitationDialog,
  setAlert,
  clearAlert,
  resetAllSelections,
  toggleTraineesRemoved,
  setTraineeList,
  setTraineePreferences,
  traineePreferences,
  traineesSelected
) => {
  const { individualSelections, selectAllState } = traineesSelected;

  const traineeIds = Object.keys(individualSelections).map(
    (link) => link.split("/").slice(-1)[0]
  );

  const filters =
    selectAllState !== SelectAllStates.NONE_SELECTED
      ? retrieveGetTraineeParams(traineePreferences)
      : {};

  const data =
    selectAllState !== SelectAllStates.NONE_SELECTED
      ? { excludedIds: traineeIds }
      : { includedIds: traineeIds };

  try {
    clearAlert();
    const response = await axios.delete(
      `/submissions/${submissionId}/trainees`,
      {
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
        },
        params: {
          ...filters,
        },
        data: data,
      }
    );
    if (response) {
      resetAllSelections();
      toggleTraineesRemoved((prevState) => !prevState);
      setOpenSendInvitationDialog(false);
      const alertMessage = i18n.t(
        "removedTrainees.notification.traineesRemoved",
        {
          numberOfTrainees: response.data.countDeleted ?? 0,
        }
      );
      setAlert("success", alertMessage, true);
      setTraineePreferences({
        ...traineePreferences,
        traineeValues: {
          ...traineePreferences.traineeValues,
          renderCount: traineePreferences.traineeValues.renderCount + 1,
        },
      });
    }
  } catch (error) {
    setAlert("error", error.message, false, false);
  }
};
